import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Slider } from '../../model/slider';
import { Observable } from 'rxjs/internal/Observable';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

declare var jQuery: any;
declare var nowuiKit: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  private sliderCollection: AngularFirestoreCollection<Slider>;
  slider: Observable<Slider[]>;

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private _sanitizer: DomSanitizer
  ) {
    this.sliderCollection = afs.collection<Slider>('slider', ref => ref.orderBy('sort'));

    this.slider = this.sliderCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Slider;
          const ref = this.storage.ref('assets/slider/' + data.url + '.jpg');
          data.o_image = ref.getDownloadURL();
          const id = a.payload.doc.id;
          return {id, ...data};
        }
      ))
    );
  }

  ngOnInit() {
    /*const $navbar = jQuery('.navbar[color-on-scroll]');
    const scroll_distance = $navbar.attr('color-on-scroll') || 500;

    // Check if we have the class "navbar-color-on-scroll" then add the function to remove the class "navbar-transparent" so it will transform to a plain color.
    if (jQuery('.navbar[color-on-scroll]').length !== 0) {
      nowuiKit.checkScrollForTransparentNavbar();
      jQuery(window).on('scroll', nowuiKit.checkScrollForTransparentNavbar);
    }*/
  }

  getFullYear() {
    return new Date().getFullYear();
  }

}
