// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA8Lx4RjJubcbPfq4C-cbbFQkALk2k2SXg',
    authDomain: 'fins-a02ef.firebaseapp.com',
    databaseURL: 'https://fins-a02ef.firebaseio.com',
    projectId: 'fins-a02ef',
    storageBucket: 'fins-a02ef.appspot.com',
    messagingSenderId: '189308623176'
  }
};
