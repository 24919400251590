import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { LandingComponent } from './layout/landing/landing.component';
import { DefaultComponent } from './layout/default/default.component';
import { NavbarModule } from './shared/navbar/navbar.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { Default2Component } from './layout/default2/default2.component';
import { Full360Component } from './layout/full-360/full-360.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DefaultComponent,
    Default2Component,
    Full360Component
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload'
    }),
    NavbarModule,
    AngularFireModule.initializeApp(environment.firebase, 'fins-web'),
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  providers: [
    { provide: StorageBucket, useValue: 'gs://fins-a02ef.appspot.com/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
