import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default2',
  templateUrl: './default2.component.html',
  styleUrls: ['./default2.component.css']
})
export class Default2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
