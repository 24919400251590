import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var jQuery: any;
declare var nowuiKit: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() transparentStart = true;
  @Input() colorOnScroll = 500;
  public transparent = true;
  public yPosition = window.pageYOffset;
  private last_page = '';

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.yPosition = window.pageYOffset;
    this.checkScrollForTransparentNavbar();
  }

  constructor(
    private router: Router
  ) {
    router.events.subscribe((val) => {
      if (this.last_page !== window.location.href) {
        this.last_page = window.location.href;
        const toggle = jQuery(this);
        jQuery('html').removeClass('nav-open');
        nowuiKit.misc.navbar_menu_visible = 0;
        setTimeout(function() {
          toggle.removeClass('toggled');
          jQuery('#bodyClick').remove();
        }, 550);
      }
    });
  }

  ngOnInit() {
    this.checkScrollForTransparentNavbar();
  }

  checkScrollForTransparentNavbar() {
    const context = this;
    if (this.transparentStart) {
      if (window.scrollY > context.colorOnScroll) {
        if (context.transparent) {
          context.transparent = false;
        }
      } else {
        if (!context.transparent) {
          context.transparent = true;
        }
      }
    } else {
      this.transparent = false;
    }
  }
}
