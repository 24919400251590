import { Routes } from '@angular/router';
import { LandingComponent } from './layout/landing/landing.component';
import { DefaultComponent } from './layout/default/default.component';
import { Default2Component } from './layout/default2/default2.component';
import { Full360Component } from './layout/full-360/full-360.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/home/home.module#HomeModule'
      }
    ]
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: 'project',
        loadChildren: './pages/project/project.module#ProjectModule'
      }
    ]
  },
  {
    path: '',
    component: Default2Component,
    children: [
      {
        path: 'project/:id',
        loadChildren: './pages/project-detail/project-detail.module#ProjectDetailModule'
      }
    ]
  },
  {
    path: '',
    component: Full360Component,
    children: [
      {
        path: 'pano',
        loadChildren: './pages/pano/pano.module#PanoModule'
      }
    ]
  }
];

